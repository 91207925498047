<template>
  <div>
    <list ref="refModelList" sort-by="tipoEquipoMarcaModeloId" refetch-records-name="modelos"
      key-field="TipoEquipoMarcaModeloId" :actions="actions" :filters.sync="filters" :table-columns="tableColumns"
      :table-actions="tableActions" :refetch-records="fetchModels" :sort-dir-desc="false" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import tiService from '@/services/informationTecnology.service'
import List from '@/components/List.vue'

export default {
  components: {
    List,
  },
  setup() {
    const { fetchModels } = tiService()
    const refBrandsList = ref(null)
    const filters = ref([
      {
        type: 'select',
        name: 'tipoMarcaId',
        label: i18n.t('AppTI.brand.name'),
        value: '',
        options: [],
        domainName: 'tipoMarca',
      },
      {
        type: 'select',
        name: 'tipoEquipoId',
        label: i18n.t('AppTI.equipment.type'),
        value: '',
        options: [],
        domainName: 'tipoEquipo',
      },
    ])
    const tableColumns = [
      {
        key: 'tipoEquipoMarcaModeloId',
        label: i18n.t('AppTI.columns.id'),
        sortable: true,
        sortKey: 'TipoMarcaId',
      },
      {
        key: 'nombre',
        label: i18n.t('AppTI.columns.name'),
        sortable: false,
      },
      {
        key: 'tipoMarca.nombre',
        label: i18n.t('AppTI.brand.name'),
        sortable: false,
      },
      {
        key: 'tipoEquipo.nombre',
        label: i18n.t('AppTI.equipment.type'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-model-edit',
        params: ['tipoEquipoMarcaModeloId'],
        icon: 'Edit2Icon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('AppTI.actions.newModel'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-model-add',
        icon: 'PlusCircleIcon',
      },
    ])

    return {
      refBrandsList,
      filters,
      tableColumns,
      tableActions,
      actions,
      fetchModels,
    }
  },
}
</script>
